let initialState = {
  getPvtRakis49Balance: "",
  canStakedRakis49QuatoAmount: "",
  getPvtRakis49Allowance: "",
  getRakis49stakerDataArray: "",
  pvtRakis49WithdrawAmount: "",
  pvtRakis49WithdrawalTime: "",
  pvtRakis49WithdrawIndex: "",
  successPvtRakis49Apporve: false,
  getPvtRakis49APR: "",
};

function pvtRakis49ViewReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "GET_RAKIS49_VIEW":
      return {
        ...state,
        getPvtRakis49Balance: payload.getPvtRakis49Balance,
        canStakedRakis49QuatoAmount: payload.canStakedRakis49QuatoAmount,
        getPvtRakis49APR: payload.getPvtRakis49APR,
      };

    case "GET_RAKIS49_APPROVE_STATE":
      return {
        ...state,
        getPvtRakis49Allowance: payload.getPvtRakis49Allowance,
      };

    case "GET_RAKIS49_WITHDRAW_LIST":
      return {
        ...state,
        getRakis49stakerDataArray: payload.getRakis49stakerDataArray,
      };

    case "GET_RAKIS49_WITHDRAW_VIEW":
      return {
        ...state,
        pvtRakis49WithdrawAmount: payload.pvtRakis49WithdrawAmount,
        pvtRakis49WithdrawalTime: payload.pvtRakis49WithdrawalTime,
        pvtRakis49WithdrawIndex: payload.pvtRakis49WithdrawIndex,
      };
    case "PVT_RAKIS49_APPROVE_SUCCESS":
      return {
        ...state,
        successPvtRakis49Apporve: payload.successPvtRakis49Apporve,
      };

    default:
      return { ...state };
  }
}

export default pvtRakis49ViewReducer;
