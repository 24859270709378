let initialState = {
  rakis6StakingBalanceOf: 0,
  canStakedQuatoAmount: "",
  allowance: 0,
  successRakis6Apporve: false,
  stakerDataArray: "",
  rakis6WithdrawAmount: "",
  pvtRakis6WithdrawalTimeToModal: "",
  withdrawIndex: "",
};

function rakis6AirDropViewReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "RAKIS6_AIRDROP_VIEW":
      return {
        ...state,
        rakis6StakingBalanceOf: payload.rakis6StakingBalanceOf,
        canStakedQuatoAmount: payload.canStakedQuatoAmount,
        allowance: payload.allowance,
      };

    case "RAKIS6_AIRDROP_APPROVE_SUCCESS":
      return {
        ...state,
        successRakis6Apporve: payload.successRakis6Apporve,
      };

    case "WITHDRAW_TOKEN_LIST":
      return {
        ...state,
        stakerDataArray: payload.stakerDataArray,
      };

    case "WITHDRAW_VIEW_SUCCESS":
      return {
        ...state,
        rakis6WithdrawAmount: payload.rakis6WithdrawAmount,
        pvtRakis6WithdrawalTimeToModal: payload.pvtRakis6WithdrawalTimeToModal,
        withdrawIndex: payload.withdrawIndex,
      };

    default:
      return { ...state };
  }
}

export default rakis6AirDropViewReducer;
