let initialState = {
  opHanBonus: "",
  opMusikhan: "",
  opTokenomicAdLockedLiquidity: "",
  opTokenomicAdLiquidity: "",
  opPvtRakis6: "",
  opRakis6: "",
  opTokenomicLiquidityReward: "",
  opTokenomicLiquidityReward1: "",
  opTokenomicLiquidityReward2: "",
  opTokenomicLiquidityReward3: "",
  opTokenomicLiquidityReward4: "",
  opTokenomicLiquidityV2Reward1: "",
  opTokenomicLiquidityV2Reward2: "",
  opTokenomicLiquidityV2Reward3: "",
  opTokenomicLiquidityV2Reward4: "",
  opTokenomicLockedHanBonus: "",
  opTokenomicLockedHanBonusV2: "",
  opTokenomicLockedHanBonusV3: "",
  hanBonusOneYear: "",
  musikhanOneYear: "",
  pvtRakis6OneYear: "",
  rakis6OneYear: "",
  opTokenMarketing: "",
  opTokenAirDropDistribute: "",
  opTokenHanEpToHanChain: "",
  opHanBonusToHanChainOneYear: "",
  opTokenomicAdLockedLiquidityOneYear: "",
  opTokenomicAdLiquidityOneYear: "",
  opRewardLiquidityOneYear: "",
  opRewardLiquidity1OneYear: "",
  opRewardLiquidity2OneYear: "",
  opRewardLiquidity3OneYear: "",
  opRewardLiquidity4OneYear: "",
  opRewardV2Liquidity1OneYear: "",
  opRewardV2Liquidity2OneYear: "",
  opRewardV2Liquidity3OneYear: "",
  opRewardV2Liquidity4OneYear: "",
  opTokenomicLockedHanBonusOneYear: "",
  opTokenomicLockedHanBonusV2OneYear: "",
  opTokenomicLockedHanBonusV3OneYear: "",
};

function opHanTokenomicReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "GET_OP_HAN_TOKENOMIC":
      return {
        ...state,
        opHanBonus: payload.opHanBonus,
        opMusikhan: payload.opMusikhan,
        opTokenomicAdLockedLiquidity: payload.opTokenomicAdLockedLiquidity,
        opTokenomicAdLiquidity: payload.opTokenomicAdLiquidity,
        opPvtRakis6: payload.opPvtRakis6,
        opRakis6: payload.opRakis6,
        opTokenomicLiquidityReward: payload.opTokenomicLiquidityReward,
        opTokenomicLiquidityReward1: payload.opTokenomicLiquidityReward1,
        opTokenomicLiquidityReward2: payload.opTokenomicLiquidityReward2,
        opTokenomicLiquidityReward3: payload.opTokenomicLiquidityReward3,
        opTokenomicLiquidityReward4: payload.opTokenomicLiquidityReward4,
        opTokenomicLiquidityV2Reward1: payload.opTokenomicLiquidityV2Reward1,
        opTokenomicLiquidityV2Reward2: payload.opTokenomicLiquidityV2Reward2,
        opTokenomicLiquidityV2Reward3: payload.opTokenomicLiquidityV2Reward3,
        opTokenomicLiquidityV2Reward4: payload.opTokenomicLiquidityV2Reward4,
        opTokenomicLockedHanBonus: payload.opTokenomicLockedHanBonus,
        opTokenomicLockedHanBonusV2: payload.opTokenomicLockedHanBonusV2,
        opTokenomicLockedHanBonusV3: payload.opTokenomicLockedHanBonusV3,
        hanBonusOneYear: payload.hanBonusOneYear,
        musikhanOneYear: payload.musikhanOneYear,
        pvtRakis6OneYear: payload.pvtRakis6OneYear,
        rakis6OneYear: payload.rakis6OneYear,
        opTokenMarketing: payload.opTokenMarketing,
        opTokenAirDropDistribute: payload.opTokenAirDropDistribute,
        opTokenHanEpToHanChain: payload.opTokenHanEpToHanChain,
        opHanBonusToHanChainOneYear: payload.opHanBonusToHanChainOneYear,
        opTokenomicAdLockedLiquidityOneYear: payload.opTokenomicAdLockedLiquidityOneYear,
        opTokenomicAdLiquidityOneYear: payload.opTokenomicAdLiquidityOneYear,
        opRewardLiquidityOneYear: payload.opRewardLiquidityOneYear,
        opRewardLiquidity1OneYear: payload.opRewardLiquidity1OneYear,
        opRewardLiquidity2OneYear: payload.opRewardLiquidity2OneYear,
        opRewardLiquidity3OneYear: payload.opRewardLiquidity3OneYear,
        opRewardLiquidity4OneYear: payload.opRewardLiquidity4OneYear,
        opRewardV2Liquidity1OneYear: payload.opRewardV2Liquidity1OneYear,
        opRewardV2Liquidity2OneYear: payload.opRewardV2Liquidity2OneYear,
        opRewardV2Liquidity3OneYear: payload.opRewardV2Liquidity3OneYear,
        opRewardV2Liquidity4OneYear: payload.opRewardV2Liquidity4OneYear,
        opTokenomicLockedHanBonusOneYear: payload.opTokenomicLockedHanBonusOneYear,
        opTokenomicLockedHanBonusV2OneYear: payload.opTokenomicLockedHanBonusV2OneYear,
        opTokenomicLockedHanBonusV3OneYear: payload.opTokenomicLockedHanBonusV3OneYear,
      };
    default:
      return { ...state };
  }
}

export default opHanTokenomicReducer;
